<template>
  <div>
    <div class="d-flex flex-column">
      <v-menu
        v-model="helpers.positionSelector.time.model"
        offset-y
        :max-width="250"
        :min-width="250"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-timeline-clock</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-text-field
              type="date"
              outlined
              label="Startdatum"
              dense
              hide-details="auto"
              class="mb-4"
            ></v-text-field>
            <v-text-field
              type="time"
              outlined
              label="Startzeit"
              dense
              hide-details="auto"
              class="mb-4"
            ></v-text-field>
            <v-text-field
              type="date"
              outlined
              label="Enddatum"
              dense
              hide-details="auto"
              class="mb-4"
            ></v-text-field>
            <v-text-field
              type="time"
              outlined
              label="Endzeit"
              dense
              hide-details="auto"
            ></v-text-field>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn
              text
              @click="helpers.responsibleSelector.model = false"
              class="ml-2"
            >
              Abbrechen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="reassignReport" class="mr-2">
              <v-icon left>mdi-account-plus</v-icon>Speichern
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
      <v-menu
        v-model="helpers.positionSelector.details.model"
        :max-width="380"
        :min-width="380"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <!-- @click="
                                            deletePositionAssignment(
                                              unit.id,
                                              position.id,
                                              user.uid
                                            )
                                          " -->
            <v-icon>mdi-card-text</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-select
              outlined
              dense
              item-text="title"
              item-value="id"
              return-object
              hide-details="auto"
              label="Abrechnungsart"
              class="mb-2"
            ></v-select>

            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="Bemerkung"
              class="mt-2"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "report-unit-person-actions",
  props: {
    // conditionItem: {},
  },
  components: {},
  data() {
    return {
      helpers: {
        positionSelector: {
          time: {
            model: false,
          },
          details: {
            model: false,
          },
        },
      },
    };
  },
  //   model: {
  //     prop: "conditionItem",
  //     event: "conditionItemChange",
  //   },
  created() {},
  computed: {
    // conditionItemLocal: {
    //   get: function () {
    //     return this.conditionItem;
    //   },
    //   set: function (value) {
    //     this.$emit("conditionItemChange", value);
    //   },
    // },
  },
  methods: {},
};
</script>
