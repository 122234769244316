<template>
  <v-card outlined>
    <v-card-title class="subtitle-1 font-weight-medium pb-2">{{
      unit.title
    }}</v-card-title>
    <v-img
      :src="unit.photoUrl.size400x400"
      contain
      height="80px"
      class="mb-4"
    ></v-img>
    <v-divider></v-divider>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody v-if="unit.selectedCrewCombination.positions.length > 0">
          <tr class="v-row-group__header">
            <th colspan="2">Besetzung</th>
          </tr>
          <tr
            v-for="position in unit.selectedCrewCombination.positions"
            :key="position.id"
          >
            <td class="font-weight-medium">
              <span class="hidden-md-and-up">{{
                position.position.shortTitle
              }}</span>
              <span class="hidden-sm-and-down">{{
                position.position.title
              }}</span
              ><span v-if="position.isOptional">*</span>
            </td>
            <td v-if="position.assignedPersons.length > 0">
              <v-chip
                v-for="(assignedPerson, index) in position.assignedPersons"
                :key="index"
                small
                outlined
                label
                class="my-1"
              >
                <span
                  class="d-inline-block text-truncate"
                  style="max-width: 140px"
                >
                  {{ assignedPerson.user.displayName }}</span
                ></v-chip
              >
            </td>
            <td v-else>
              <v-chip small outlined label class="my-1"> Nicht besetzt</v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  name: "report-unit-card",
  components: {},
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};
</script>
